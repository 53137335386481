<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/light1/download.jpg" width="100%">
    <div class="solarEnergy_box">
    <div class="solarEnergy_box_1">
      <img src="../../../../public/images/IPC/network/2.jpg" width="400px" height="400px">
    </div>
    <div class="solarEnergy_box_2">
      <h1>智能摄像灯2</h1>
      <p>品牌：维拍</p>
      <p>产品功能：智能摄像机和照明灯结合</p>
      <p>内存容量：8G 16G 32G</p>
      <p>智能类型：安卓 IOS</p>
      <p>质保年限：1年</p>
      <p>颜色：黑色</p>
    </div>
  </div>
    <div class="tile">详情介绍</div>
    <div class="light1_box">
      <table align="center" border="1" style="width:900px;">
        <tbody>
        <tr>
          <td width="551" valign="center" colspan="2">
            <p align="center"><strong>智能摄像灯</strong><strong> </strong></p>
          </td>
        </tr>
        <tr>
          <td width="551" valign="top" colspan="2">
            <p><strong>描述：家庭安全的第一道防线当检测到移动物体时，警笛报警，同时推送报警信息至手机app，提前发现危险</strong><strong><br />
            </strong>&nbsp;优势 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
              1、高清200万像素<br />
              2、远程可视对讲 <br />
              3、支持IR-Cut，白天不偏色，夜视也清晰<br />
              4、支持128G TF卡循环录像 <br />
              5、PIR人体侦测报警 <br />
              6、远程灯光亮度调节及开关 <br />
              7、报警信息推送手机APP &nbsp;</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>&nbsp;</p>
          </td>
          <td width="399" valign="center">
            <p>&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>供电</p>
          </td>
          <td width="399" valign="center">
            <p>100V～240V AC</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>视频</p>
          </td>
          <td width="399" valign="center">
            <p>1080P</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>图像传感器</p>
          </td>
          <td width="399" valign="center">
            <p>1/2.7英寸1080p逐行扫描CMOS传感器</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>最低照度</p>
          </td>
          <td width="399" valign="center">
            <p>0.5Lux（彩色模式）,0.1Lux（黑白模式）</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>镜头角度</p>
          </td>
          <td width="399" valign="center">
            <p>150度</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>夜视</p>
          </td>
          <td width="399" valign="center">
            <p>双滤光片自动切换，8颗贴片红外灯，照射距离10米</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>压缩标准</p>
          </td>
          <td width="399" valign="center">
            <p>H.264 main profile@level:4.0/Motion-JPEG</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>音频输入</p>
          </td>
          <td width="399" valign="center">
            <p>内置 －48dB 麦克风</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>音频输出</p>
          </td>
          <td width="399" valign="center">
            <p>内置 8Ω 1W 喇叭</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>网络</p>
          </td>
          <td width="399" valign="center">
            <p>802.11b/g/n &nbsp;&nbsp;&nbsp;WiFi 2.4GHz</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>存储功能</p>
          </td>
          <td width="399" valign="center">
            <p>支持T-Flash卡(最高支持128GB)</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>报警检测</p>
          </td>
          <td width="399" valign="center">
            <p>支持移动侦测</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>光源</p>
          </td>
          <td width="399" valign="center">
            <p>2颗*白光LED</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center">
            <p>流明</p>
          </td>
          <td width="399" valign="center">
            <p>300-400ML</p>
          </td>
        </tr>
        <tr>
          <td width="151" valign="center" rowspan="2">
            <p>远程控制</p>
          </td>
          <td width="399" valign="center">
            <p>灯光远程控制（开/关/亮度控制）</p>
          </td>
        </tr>
        <tr>
          <td width="399" valign="center">
            <p>远程报警信息关闭等</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import foot from '../../../../src/components/foot'
import elMenu from "../../../components/elMenu";
import navigation from "../../../components/navigation";

export default {
  name: "light2",
  components: {
    elMenu, foot,navigation
  },
}
</script>

<style scoped>
.solarEnergy_box {
  width: 1280px;
  margin: 10px auto;
  height: 430px;
  border: 1px #ececec solid;
}

.solarEnergy_box_1 {
  width: 45%;
  float: left;
  height: 400px;
  text-align: center !important;
}

.solarEnergy_box_2 {
  width: 51%;
  float: left;
  height: 400px;
  border-left: 1px solid #ececec;
  padding-left: 25px;
  margin-top: 15px;
}

.tile {
  text-align: center;
  font-size: 24px;
  margin: 70px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ececec;
  margin: 0px auto;
}

.light1_box {
  height: 1300px;
}

</style>